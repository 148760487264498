import { useQuery } from '@tanstack/react-query'
import { fetchClient } from '../../helpers/transport'
import { QUERY_KEYS } from '../../helpers'

export async function getSession(): Promise<boolean | undefined> {
  try {
    await fetchClient('/session')
    return true
  } catch (error) {
    throw new Error('Invalid Session')
  }
}

export const useGetSession = (userId?: number) =>
  useQuery([QUERY_KEYS.SESSION, userId], () => getSession(), {
    enabled: Boolean(userId),
  })
